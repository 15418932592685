@use "styles/abstracts";

.footer {
  color: abstracts.$neutral;
  @include abstracts.paddingBox;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.attribution {
  margin-top: abstracts.$margin;
  display: flex;
}

.webAuthor {
  margin: 0 1rem;
  text-align: center;
}

@media screen and (min-width: abstracts.$tablet) {
  .container {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }

  .attribution {
    margin-top: 0;
  }

  .web-author {
    text-align: right;
  }
}