@use "styles/abstracts";

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0;
  background-color: abstracts.$primary;
  padding: abstracts.$space-sm;
}

.mainNavWrapper {
  display: flex;
}

// Hamburger
.hamburger {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 1px solid red;
  margin: 0 1rem * abstracts.$goldenRatio;
  overflow: visible;
  z-index: 2;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.isActive:hover {
  opacity: 0.7;
}

.hamburger.isActive .hamburgerInner,
.hamburger.isActive .hamburgerInner::before,
.hamburger.isActive .hamburgerInner::after {
  background-color: abstracts.$black;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburgerInner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburgerInner,
.hamburgerInner::before,
.hamburgerInner::after {
  width: 40px;
  height: 4px;
  background-color: abstracts.$primary;
  border-radius: 4px;
  position: absolute;
  transition-property: all;
  transition-duration: abstracts.$duration;
  transition-timing-function: ease;
}

.hamburgerInner::before,
.hamburgerInner::after {
  content: "";
  display: block;
}

.hamburgerInner::before {
  top: -10px;
}

.hamburgerInner::after {
  bottom: -10px;
}

.hamburgerSqueeze .hamburgerInner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburgerSqueeze .hamburgerInner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburgerSqueeze .hamburgerInner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburgerSqueeze.isActive .hamburgerInner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburgerSqueeze.isActive .hamburgerInner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburgerSqueeze.isActive .hamburgerInner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media screen and (min-width: abstracts.$tablet) {
  .mainNavWrapper {
    display: flex;
  }

  .hamburger {
    display: none;
  }
}