@import "styles";

.mainWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & main {
    min-height: 100%;
    max-height: 100%;
    // overflow-y: auto;
  }
}
