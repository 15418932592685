@use "styles/abstracts";

.LoginRegister {
  color: abstracts.$primary;
  display: grid;
  place-content: center;

  div {
    padding: abstracts.$padding * 0.618 abstracts.$padding;
    font-size: 0.725rem;
    color: abstracts.$white;

    & a {
      color: abstracts.$white;
      text-decoration: underline;
    }

    & span {
      margin: 0 abstracts.$margin * 0.38;
    }
  }
}