/* ----------------------------------------------
 * Generated by Animista on 2021-1-10 8:52:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-up
 * ----------------------------------------
 */
 @-webkit-keyframes fade-up {
    0% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
  }
  @keyframes fade-up {
    0% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
  }




  /* ----------------------------------------------
 * Generated by Animista on 2021-1-10 8:52:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-left
 * ----------------------------------------
 */
 @-webkit-keyframes fade-left {
    0% {
        -webkit-transform: translateX(80px);
        transform: translateX(80px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
  }
  @keyframes fade-left {
    0% {
        -webkit-transform: translateX(80px);
        transform: translateX(80px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
  }




  /* ----------------------------------------------
 * Generated by Animista on 2021-1-12 6:43:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  


  /* ----------------------------------------------
 * Generated by Animista on 2021-1-12 9:17:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  



  /* ----------------------------------------------
 * Generated by Animista on 2021-1-12 10:6:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }

  



  
 /* ----------------------------------------------
 * Generated by Animista on 2021-1-12 10:3:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out-top
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      opacity: 0;
    }
  }
  @keyframes fade-out-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      opacity: 0;
    }
  }




  /* ----------------------------------------------
 * Generated by Animista on 2021-1-14 16:57:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}




/* ----------------------------------------------
 * Generated by Animista on 2021-1-14 17:1:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes puff-in-center {
    0% {
      -webkit-transform: scale(1.3);
              transform: scale(1.3);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes puff-in-center {
    0% {
      -webkit-transform: scale(1.3);
              transform: scale(1.3);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  
  


// Turn On Overflow
@-webkit-keyframes turn-on-overflow {
    0% {
        overflow: hidden;
    }
    100% {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
    }
  }
  @keyframes turn-on-overflow {
    0% {
        overflow: hidden;
    }
    100% {
        overflow: auto;
        // -webkit-overflow-scrolling: touch;
    }
  }


  /* ----------------------------------------------
 * Generated by Animista on 2021-1-14 17:9:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-right {
    0% {
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-right {
    0% {
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  


  /* ----------------------------------------------
 * Generated by Animista on 2021-1-14 17:33:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.155, 0.85, 1);
              transform: scale3d(1.155, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.155, 0.85, 1);
              transform: scale3d(1.155, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  }
  

//   Crown Bottom Left Entrance Animation
  @-webkit-keyframes crown-bottom-left {
    0% {
      left: 20px;
      bottom: 0;
      opacity: 0;
    }
    100% {
        left: 0;
        bottom: 0;
        opacity: 1;
    }
  }
  @keyframes crown-bottom-left {
    0% {
      left: 20px;
      bottom: 0;
      opacity: 0;
    }
    100% {
      left: 0;
      bottom: 0;
      opacity: 1;
    }
  }
  

//   Crown Left Entrance Animation
  @-webkit-keyframes crown-left {
    0% {
        left: 39px;
        right: 0;
        top: 44px;
        -webkit-transform: rotate(-115deg);
        transform: rotate(-115deg);
      opacity: 0;
    }
    100% {
        left: 29px;
        right: 0;
        top: 44px;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        opacity: 1;
    }
  }
  @keyframes crown-left {
    0% {
      left: 39px;
      right: 0;
      top: 44px;
      -webkit-transform: rotate(-115deg);
      transform: rotate(-115deg);
      opacity: 0;
    }
    100% {
      left: 29px;
      right: 0;
      top: 44px;
      transform: rotate(-135deg);
      opacity: 1;
    }
  }
  
  

//   Crown Center Entrance Animation
  @-webkit-keyframes crown-center {
    0% {
        top: 32px;
        left: 50%;
        right: 50%;
        -webkit-transform: translate(-54%, -50%) rotate(90deg);
        transform: translate(-54%, -50%) rotate(90deg);
        opacity: 0;
    }
    100% {
        top: 22px;
        left: 50%;
        right: 50%;
        -webkit-transform: translate(-54%, -50%) rotate(90deg);
        transform: translate(-54%, -50%) rotate(90deg);
        opacity: 1;
    }
  }
  @keyframes crown-center {
    0% {
        top: 32px;
        left: 50%;
        right: 50%;
        -webkit-transform: translate(-54%, -50%) rotate(90deg);
        transform: translate(-54%, -50%) rotate(90deg);
        opacity: 0;
    }
    100% {
        top: 22px;
        left: 50%;
        right: 50%;
        -webkit-transform: translate(-54%, -50%) rotate(90deg);
        transform: translate(-54%, -50%) rotate(90deg);
        opacity: 1;
    }
  }
  
  
  

//   Crown Right Entrance Animation
  @-webkit-keyframes crown-right {
    0% {
        left: 19px;
        right: 0;
        top: 45px;
        -webkit-transform: rotate(115deg);
        transform: rotate(115deg);
        opacity: 0;
    }
    100% {
        left: 29px;
        right: 0;
        top: 45px;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        opacity: 1;
    }
  }
  @keyframes crown-right {
    0% {
        left: 19px;
        right: 0;
        top: 45px;
        -webkit-transform: rotate(115deg);
        transform: rotate(115deg);
        opacity: 0;
    }
    100% {
        left: 29px;
        right: 0;
        top: 45px;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        opacity: 1;
    }
  }
  


  //   Crown Bottom Right Entrance Animation
  @-webkit-keyframes crown-bottom-right {
    0% {
      right: 20px;
      bottom: 0;
      opacity: 0;
    }
    100% {
        right: 0;
        bottom: 0;
        opacity: 1;
    }
  }
  @keyframes crown-bottom-right {
    0% {
      right: 20px;
      bottom: 0;
      opacity: 0;
    }
    100% {
      right: 0;
      bottom: 0;
      opacity: 1;
    }
  }
  


  
  // Winner Box Shadow Fade Entrance Animation
  @-webkit-keyframes winner-box-shadow-fade {
    0% {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.70);
    }
  }
  @keyframes winner-box-shadow-fade {
    0% {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.70);
    }
  }
  


  // Scale Up Entrance Animation
  @-webkit-keyframes scale-up {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0;
    }
    80% {
        -webkit-transform: scale(1.15);
        transform: scale(1.15);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
  }
  @keyframes scale-up {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0;
    }
    80% {
        -webkit-transform: scale(1.15);
        transform: scale(1.15);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
  }



  // Poster Left Entrance Animation
  @-webkit-keyframes poster-left {
    0% {
        left: 100px;
        opacity: 0;
    }
    100% {
        left: 40px;
        opacity: 1;
    }
  }
  @keyframes poster-left {
    0% {
        left: 100px;
        opacity: 0;
    }
    100% {
        left: 40px;
        opacity: 1;
    }
  }


  // Poster Back Left Entrance Animation
  @-webkit-keyframes poster-back-left {
    0% {
        left: 100px;
        opacity: 0;
    }
    100% {
        left: 80px;
        opacity: 1;
    }
  }
  @keyframes poster-back-left {
    0% {
        left: 100px;
        opacity: 0;
    }
    100% {
        left: 80px;
        opacity: 1;
    }
  }

  
  // Poster Right Entrance Animation
  @-webkit-keyframes poster-right {
    0% {
        right: 100px;
        opacity: 0;
    }
    100% {
        right: 40px;
        opacity: 1;
    }
  }
  @keyframes poster-right {
    0% {
        right: 100px;
        opacity: 0;
    }
    100% {
        right: 40px;
        opacity: 1;
    }
  }



  // Poster Back Right Entrance Animation
  @-webkit-keyframes poster-back-right {
    0% {
        right: 100px;
        opacity: 0;
    }
    100% {
        right: 80px;
        opacity: 1;
    }
  }
  @keyframes poster-back-right {
    0% {
        right: 100px;
        opacity: 0;
    }
    100% {
        right: 80px;
        opacity: 1;
    }
  }