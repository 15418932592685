@import "styles";

.NotFound {
  display: grid;
  place-content: center;
  min-height: 100%;
  text-align: center;

  h1,
  p,
  a {
    &:first-letter {
      text-transform: capitalize;
    }
  }

  a {
    text-decoration: underline;
  }
}
