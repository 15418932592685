@use 'styles/abstracts';
@use 'styles/base';
@use 'sass:list';

h1 {
  color: abstracts.$primary;
}

// This sets heading elements to the scale,
//   and creates utility classes
//   "heading-1, heading-2"

$size-difference: 100;

$font-size: 800;
$margin-size: 300;
$line-height-size: 000; // 1.35 1.7 ~1.5

$letter-spacing-list: (
  -.06rem,
  -.04rem,
  -.02rem,
  0,
  .12rem,
  .18rem
);

@for $i from 1 through 6 {

  h#{$i},
  .heading-#{$i} {
    color: abstracts.color(neutral, 500);
    font-family: base.$fontStack;
    font-size: abstracts.rem-scale($font-size);
    font-weight: abstracts.$fw-lg;
    line-height: (1 + abstracts.scale($line-height-size));
    letter-spacing: list.nth($letter-spacing-list, $i);

    margin-block-start: abstracts.rem-scale($margin-size);
    margin-block-end: abstracts.rem-scale($margin-size);
  }

  $font-size: $font-size - $size-difference;
  $margin-size: $margin-size + $size-difference;
  $line-height-size: $line-height-size + $size-difference;
}

.screenHeading {
  font-size: abstracts.$fs-sm-em;
  text-transform: uppercase;
  line-height: 1;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}