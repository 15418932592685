@use "styles/abstracts";

.loading_spinner-wrap {
  width: 100%;
  padding: 50px 0;
}

.loading_spinner {
  display: block;
  margin: 0 auto;
  fill: abstracts.$primary;
}

.loading_spinner circle {
  animation-name: upAndDown;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.05, 0.2, 0.35, 1);
  animation-iteration-count: infinite;
}

.loading_spinner circle:nth-child(2) {
  animation-delay: 0.18s;
}

.loading_spinner circle:nth-child(3) {
  animation-delay: 0.36s;
}

@keyframes upAndDown {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  25% {
    opacity: 1;
    transform: translateY(-10px);
  }

  75% {
    opacity: 1;
    transform: translateY(-10px);
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}