@use "styles/abstracts";

.recurrence {
  margin-top: abstracts.$margin;
  display: grid;
  grid-template-columns: 38% 62%;
  background: abstracts.$primary;
  padding: abstracts.$padding;
  color: abstracts.$white;

  & p {
    grid-column: 1 / 3;
    color: lighten(abstracts.$primary, 38%);
    font-weight: 600;
  }

  & .radioGroup {
    grid-column: 1 / 2;

    & .item {
      // justify-content: end;
      // align-items: bottom;

      & input {
        display: inline-block;
        width: 2rem;
      }
    }
  }

  & .numberOf {
    grid-column: 2 / 3;

    & input {
      margin: 0 abstracts.$margin;
      width: 5rem;
      padding: abstracts.$padding;
    }

    & button {
      margin: abstracts.$margin 0;
      width: 62%;
      @include abstracts.border();
      @include abstracts.paddingBox;
      @include abstracts.gradient(abstracts.$primary);
      font-size: 0.618rem;
      color: lighten(abstracts.$primary, 62%);
    }
  }
}