@use "styles/abstracts";

.project {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 abstracts.$padding;
  width: 100%;
  color: abstracts.$white;
  grid-column: 2/4;
  overflow-y: auto;

  h2 {
    // margin: 0;
  }

  p {
    font-size: abstracts.$goldenPercent;
    color: abstracts.$neutral;

    & span {
      margin-left: abstracts.$margin;
    }
  }
}

.inactive {
  color: abstracts.$neutral;
}

.status {
  font-size: abstracts.$goldenPercent;
  color: abstracts.$white;

  & span {
    margin-left: abstracts.$margin;
    color: abstracts.$successColor;
    font-weight: bold;
  }
}

.history {
  display: flex;
  justify-content: space-between;

  p {

    // color: $successColor;
    span {
      display: inline-block;
    }
  }
}