@use "styles/abstracts";

.task {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: abstracts.$black;
  color: abstracts.$white;
  // padding: abstracts.$padding;

  .task__header {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: abstracts.$margin;
    align-items: center;
  }

  .taskViewTextContainer {
    padding-inline-start: abstracts.$space-xsm;
    font-weight: abstracts.$fw-md;
  }
}