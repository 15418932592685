@use "styles/abstracts";

.selector {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  header {
    color: abstracts.color(black, 200);
    background: transparent;
    text-transform: capitalize;
  }

  ul {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    @include abstracts.scrollbar;

    li {
      font-size: min(1rem, 18px);
      background-color: abstracts.$primary;
      @include abstracts.paddingBox;

      &:nth-child(even) {
        background-color: transparent;
      }

      &:hover {
        cursor: pointer;
      }

      a {
        font-size: inherit;
        color: abstracts.$white;
      }
    }
  }
}