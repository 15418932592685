@use "styles/abstracts";

.nav {
  display: flex;
  align-items: center;

  ul {
    margin: 0;
    padding: 0;
    display: flex;

    li {
      // text-align: end;

      a {
        padding: abstracts.$space-sm abstracts.$space-xsm;
        display: inline-block;
        color: abstracts.$white;
      }
    }
  }
}

@media screen and (min-width: abstracts.$tablet) {
  .nav {
    ul {
      display: flex;

      li {
        a {
          color: abstracts.$white;
          padding: 0;
          margin-right: abstracts.$margin;

          &:hover {
            color: lighten(abstracts.$white, 32);
          }

          &.active {
            color: abstracts.$neutral;
          }
        }
      }
    }
  }
}