@use "colors";
@use 'scale-functions' as factors;

// colors
$scrollbar-thumb-background: colors.color(primary, 600);
$scrollbar-thumb-background-hover: colors.color(primary, 400);
$scrollbar-track-background: colors.color(black, 400);

/* sizes */
$margin: 1rem;
$padding: 1em;
$maxWidth: factors.px-to-rem(960px);

$fs-xsm-em: factors.em-scale(400);
$fs-sm-em: factors.em-scale(400);
$fs-md-em: factors.em-scale(500);
$fs-lg-em: 1.29em;
$fs-xlg-em: factors.em-scale(700);

$fs-xsm-rem: factors.rem-scale(400);
$fs-sm-rem: factors.rem-scale(400);
$fs-md-rem: factors.rem-scale(500);
$fs-lg-rem: 1.29rem;
$fs-xlg-erm: factors.rem-scale(700);

/* font weights */
// $fw-sm: 400;
$fw-md: 400;
$fw-lg: 700;

$space-xsm: factors.em-scale(300);
$space-sm: factors.em-scale(400);
$space-md: factors.em-scale(500);
$space-lg: factors.em-scale(600);


// speed
$duration: 0.3s;
$duration-sm: 160ms;
$duration-md: 320ms;

$ease: ease-in-out;

/* box model */
// $border: 1px solid transparentize(colors.color(brown, 900), functions.scale(500));
$border: 1px solid colors.color(neutral, 500);
$border-radius: factors.em-scale(200);

$max-width: 70ch;

$spacer-xs: .47em;
$spacer-sm: .76em;
$spacer-md: 1em;
$spacer-lg: calc($spacer-md * 1.618);

$btn-spacing-sm-btn: factors.px-to-rem(36px); // 36-48
$btn-spacing-md-btn: factors.px-to-rem(24px); // 24-36
$btn-spacing-lg-btn: factors.px-to-rem(12px); // 12-24

//factors
$goldenRatio: 1.618;
$goldenRatioDecrease: 0.618;
$large: 0.618;
$small: 0.382;
$largePercent: 61.8%;
$smallPercent: 38.2%;
$goldenPercent: 76%;

$grp: 1.618%;
$grp2: 2.618%;
$grp3: 4.236%;
$grp4: 6.854%;
$grp5: 11.089%;
$grp6: 17.942%;
$grp7: 29.03%;
$grp8: 46.971%;
$grp9: 75.999%;
$grp10: 122.966%;
$grp11: 198.959%;