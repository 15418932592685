@use "styles/abstracts";

.userMenu {
  // filler
  position: relative;
  color: abstracts.$primary;
  // display: grid;
  // place-items: center;


  & a {
    display: block;
    padding: abstracts.$padding;
    color: abstracts.$black;

    & :hover {
      color: abstracts.$white;
      background: abstracts.$primary;
      cursor: pointer;
    }
  }

  &:hover .dropdown {
    display: block;
    /* Display the dropdown */
  }

  &:focus .dropdown {
    display: block;
    /* Display the dropdown */
  }
}

.icon {
  display: flex;
  justify-content: center;
  // width: 1.618rem;
  padding: abstracts.$space-xsm;
  border: none;
  background: transparent;
  color: abstracts.$white;

  &:hover {
    cursor: pointer;
  }

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

.dropdown {
  position: absolute;
  z-index: 999;
  margin: 0;
  padding: abstracts.$padding;
  background: #f2f2f2;
  display: none;
  right: -0.38rem;
  white-space: nowrap;
  // @include boxShadow;
  @include abstracts.gradient(abstracts.$white);
}

.menu {
  position: absolute;
  top: 2rem;
  right: 0;
  height: 0;
  margin: 0;
  min-width: 100%;
  /*Setwidthofthedropdown*/
  padding: 0;
  white-space: nowrap;
  @include abstracts.boxShadow;
  @include abstracts.border;
  @include abstracts.gradient(abstracts.$white);
  opacity: 0;
  transition: all abstracts.$duration ease-in-out;
  overflow: hidden;

  &li {
    display: block;
  }

  & :hover {
    display: block;
    cursor: pointer;
  }
}

.menuOpen {
  height: 6rem;
  opacity: 1;
}

.user {
  padding: 0 abstracts.$padding;
  cursor: default;
  text-align: center;

  & .email {
    margin: abstracts.$margin 0;
    font-size: 0.725rem;
  }
}

.signout {
  @include abstracts.border();
  // border-top: $border;
  border-top: 1px solid lighten(abstracts.$black, 62%);
  padding: abstracts.$padding 0 0 0;
  font-size: 0.725rem;
  text-align: center;
  cursor: pointer;
}