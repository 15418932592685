@use "styles/abstracts";

.button {
  color: abstracts.$white;
  margin: abstracts.$margin;
  @include abstracts.border($color: abstracts.$primary);
  @include abstracts.paddingBox;
  outline: none;

  &:hover:enabled {
    @include abstracts.gradient(scale-color(abstracts.$primary, $lightness: 10%));
    cursor: pointer;
  }

  &:disabled {
    $disabledColor: scale-color(abstracts.$white, $lightness: -38%);
    color: $disabledColor;
    cursor: not-allowed;
  }

  &:focus {
    outline: 1px solid invert(abstracts.$primary);
  }

  &:active {
    transform: scale(0.9, 0.9);
    transition: all 0.15s;
  }

  &.primary {
    @include abstracts.gradient(abstracts.$primary);
  }
}