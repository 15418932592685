@use "styles/abstracts";

.Auth {
  padding: abstracts.$padding;

  form {
    padding: 4rem 0;
    display: grid;
    place-content: center;

    label {
      text-transform: capitalize;
      display: block;
    }

    input {
      margin-bottom: abstracts.$margin;
      display: block;
      color: abstracts.$black;
      @include abstracts.paddingBox;
      @include abstracts.border();

      &[type="checkbox"] {
        display: inline-block;
        margin-left: abstracts.$margin * abstracts.$goldenRatioDecrease;
        width: initial;
      }

      &:not([type="submit"]) {
        min-width: 25rem;
        user-select: auto;
      }

      &[type="submit"] {
        @include abstracts.button();
        margin-left: 0;
        margin-right: 0;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        visibility: hidden;
      }
    }
  }
}

@media screen and (min-width: abstracts.$tablet) {
  .Auth {
    .form {
      padding: 4rem 10rem;
    }
  }
}