@use "styles/abstracts";

.offCanvasContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translate3d(100vw, -61.8vh, 0);
  height: 100%;
  width: 100%;
  transition: all 0.2s ease-in;
  background-color: abstracts.$primary;
  overflow: hidden;
  z-index: -1;
}

.offscreenNavWrapper {
  opacity: 0;
  z-index: 1;
  transition: all abstracts.$duration * 2 ease-in;
  background-color: abstracts.$primary;
}

.openNav {
  transform: translate3d(0, 0, 0);
  z-index: 1;

  &.offCanvasContainer {
    transition: all 0.25s ease-out;
  }

  & .offscreenNavWrapper {
    opacity: 1;
  }
}

@media screen and (min-width: abstracts.$tablet) {
  .offCanvasContainer {
    display: none;
  }
}