@use "styles/abstracts";

.Welcome {
  width: 100%;
  min-height: 100%;
  display: grid;
  place-content: center;
}

.jumbotron {
  font-size: 1.618rem;
  // padding: 5em;
  background-color: abstracts.$primary;
  max-width: 76%;
  justify-self: center;
  // min-height: 61.8vh;
  // display: grid;
  // place-content: center;
  @include abstracts.border($width: 0);

  // .head,
  // .body {
  //   // margin: 0 auto;
  //   // padding: 2em;
  // }

  header {
    padding: 1em;
    text-align: center;
  }

  .body {
    padding: 2em;
    background-color: abstracts.$white;
    color: abstracts.$primary;
  }

  footer {
    display: flex;
    justify-content: center;
    font-size: initial;

    a {
      @include abstracts.button($bg-color: abstracts.$white, $text-color: abstracts.$primary);
      @include abstracts.button($bg-color: abstracts.$white, $text-color: abstracts.$primary);
    }
  }
}